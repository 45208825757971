import {
    IMAGE_IDENTIFIER_MEDIUM_IMAGES,
    IMAGE_IDENTIFIER_SMALL_IMAGES,
    PRIMARY_IMAGE_NAMING_PATTERN_ORDER,
    REAL_IMAGE_EXTENSIONS,
} from '@containex/portal-business-logic';
import type { Image } from '@medusajs/medusa/dist';

interface SortedImages {
    primary: Image[];
    secondary: Image[];
    tertiary: Image[];
    fourth: Image[];
    fifth: Image[];
    rest: Image[];
}

export function filterImages(images: Image[], isMobile: boolean): Image[] {
    return images.filter((image) => {
        if (isJpgImage(image)) {
            return true;
        }

        if (isMobile && image.url.toLowerCase().includes(IMAGE_IDENTIFIER_SMALL_IMAGES)) {
            return true;
        } else if (
            !isMobile &&
            !image.url.toLowerCase().includes(IMAGE_IDENTIFIER_SMALL_IMAGES) &&
            !image.url.toLowerCase().includes(IMAGE_IDENTIFIER_MEDIUM_IMAGES)
        ) {
            return true;
        }

        return false;
    });
}

export function sortedProductImages(images: Image[]): Image[] {
    const sortedImages: SortedImages = {
        primary: [],
        secondary: [],
        tertiary: [],
        fourth: [],
        fifth: [],
        rest: [],
    };

    for (const image of images) {
        if (containsNoNamingPattern(image)) {
            if (isJpgImage(image)) {
                sortedImages.primary.push(image);
            } else {
                sortedImages.secondary.push(image);
            }
        }

        if (image.url.toLowerCase().includes('perspektive')) {
            sortedImages.tertiary.push(image);
        } else if (image.url.toLowerCase().includes('grundriss')) {
            sortedImages.fourth.push(image);
        } else if (image.url.toLowerCase().includes('ansicht')) {
            sortedImages.fifth.push(image);
        } else if (!sortedImages.primary.includes(image)) {
            sortedImages.rest.push(image);
        }
    }

    return [
        sortedImages.primary.sort((a, b) => a.url.localeCompare(b.url)),
        sortedImages.secondary,
        sortedImages.tertiary,
        sortedImages.fourth,
        sortedImages.fifth,
        sortedImages.rest,
    ].flatMap((x) => x);
}

function containsNoNamingPattern(image: Image): boolean {
    for (const key of PRIMARY_IMAGE_NAMING_PATTERN_ORDER) {
        if (image.url.toLowerCase().includes(key)) {
            return false;
        }
    }

    return true;
}

function isJpgImage(image: Image): boolean {
    for (const extension of REAL_IMAGE_EXTENSIONS) {
        if (image.url.toLowerCase().includes(extension)) {
            return true;
        }
    }

    return false;
}

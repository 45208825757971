import type { CtxProduct, CtxProductVariant } from '@containex/portal-backend-api-client';
import { getAvailabilityForVariant } from '@/util/variantAvailability.ts';
import { Availability } from '@containex/portal-backend-dto';
import { isProductUsed } from '@/util/isProductUsed.ts';
import { toEuro } from '@containex/portal-business-logic';

export function getProductSchema(
    product: CtxProduct,
    mainVariant: CtxProductVariant,
    price: number,
    isRental: boolean
): string {
    return JSON.stringify({
        '@context': 'http://schema.org',
        '@type': 'Product',
        name: product.title ?? '',
        image: product.thumbnail ?? '',
        description: '',
        brand: {
            '@type': 'Brand',
            name: 'CONTAINEX',
        },
        offers: {
            '@type': 'Offer',
            url: document.URL,
            priceCurrency: 'EUR',
            price: formatSchemaPrice(price),
            availability: toSchemaAvailability(mainVariant, isRental),
            itemCondition: getItemCondition(product),
            businessFunction: getBusinessFunction(isRental),
        },
    });
}

function formatSchemaPrice(price: number): string {
    //We use the us-number format as schema.org recommends dots/fullstop to indicate the decimal point.
    const formatter = Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const priceToFormat = price < 0 ? 0 : price;
    return formatter.format(toEuro(priceToFormat));
}

function getItemCondition(mainProduct: CtxProduct): string {
    return isProductUsed(mainProduct) ? 'https://schema.org/UsedCondition' : 'https://schema.org/NewCondition';
}

function getBusinessFunction(isRental: boolean): string {
    return isRental ? 'http://purl.org/goodrelations/v1#LeaseOut' : 'http://purl.org/goodrelations/v1#Sell';
}

function toSchemaAvailability(mainVariant: CtxProductVariant, isRental: boolean): string {
    const availability = getAvailabilityForVariant(mainVariant);
    if (isRental || availability === Availability.Available) {
        return 'https://schema.org/InStock';
    } else if (availability === Availability.Backordered) {
        return 'https://schema.org/BackOrder';
    } else if (availability === Availability.OnDemand) {
        return 'https://schema.org/MadeToOrder';
    }
    return 'https://schema.org/SoldOut';
}
